var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ec-expand-box" },
    _vm._l(_vm.textList, function (item, idx) {
      return _c(
        "div",
        {
          key: item.title,
          class: ["item", idx === _vm.unfoldIndex ? "unfold" : ""],
        },
        [
          _c(
            "div",
            {
              staticClass: "title-wrapper",
              on: {
                click: function ($event) {
                  return _vm.onUnfold(item, idx)
                },
              },
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                " + _vm._s(item.title) + "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("antd-icon", {
                staticClass: "icon-right",
                attrs: { type: "icon-arrow-o" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          idx === _vm.unfoldIndex
            ? _c(
                "div",
                [
                  _vm.customeContent
                    ? _vm._t("default", null, { item: item, index: idx })
                    : _vm._t(item.key, function () {
                        return [
                          _c("div", { staticClass: "content" }, [
                            _c("div", {
                              domProps: { innerHTML: _vm._s(item.content) },
                            }),
                          ]),
                        ]
                      }),
                ],
                2
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }