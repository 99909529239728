<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-04-16 16:44:16.637 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-10-10 16:00:47 -->

<template>
    <div class="ec-expand-box">
        <div
            v-for="(item, idx) in textList"
            :key="item.title"
            :class="['item', idx === unfoldIndex ? 'unfold' : '']"
        >
            <div
                class="title-wrapper"
                @click="onUnfold(item, idx)"
            >
                <div class="title">
                    {{ item.title }}
                </div>
                <antd-icon
                    type="icon-arrow-o"
                    class="icon-right"
                />
            </div>
            <div v-if="idx === unfoldIndex">
                <slot
                    v-if="customeContent"
                    :item="item"
                    :index="idx"
                />

                <slot
                    v-else
                    :name="item.key"
                >
                    <div class="content">
                        <div v-html="item.content" />
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">

export default {
    name: 'EcExpandBox',

    props: {
        textList: {
            type: Array,
            default: () => []
        },

        customeContent: {
            type: Boolean,
            default: false
        },

        defaultUnfoldIndex: {
            type: [Number, null],
            default: null
        }
    },

    data() {
        return {
            unfoldIndex: null
        };
    },

    created() {
        this.unfoldIndex = this.defaultUnfoldIndex;
    },

    methods: {
        onUnfold(item, idx) {
            this.$emit('click', item);
            if (!item.content && !this.$slots[item.key]) return;
            this.unfoldIndex = idx === this.unfoldIndex ? null : idx;
        },

        init() {
            this.unfoldIndex = this.defaultUnfoldIndex;
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .ec-expand-box {
        padding: 10px 0;

        .item {
            position: relative;
            padding: 0.2rem;
            font-size: 16px;
            background-color: #fff;

            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }

            &:last-child {
                border-radius: 0 0 10px 10px;
            }

            &:first-child {
                border-radius: 10px 10px 0 0;
            }

            .title-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 20px;
                color: #49495c;

                .title {
                    flex-shrink: 1;
                }

                .icon-right {
                    margin-left: 0.05rem;
                    flex-shrink: 0;
                    font-size: 12px;
                    transform: rotate(0deg);
                    transition: transform 0.3s;
                    color: #dedede;
                }
            }

            &.unfold {
                .icon-right {
                    transform: rotate(90deg);
                }
            }

            &:not(.unfold) {
                .title {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .content {
                padding: 10px 0;
                font-size: 14px;
                line-height: 1.4;
                color: #9fa7b3;
                white-space: pre-wrap;
            }
        }
    }

</style>
