import { render, staticRenderFns } from "./question.vue?vue&type=template&id=5687a6c2&"
import script from "./question.vue?vue&type=script&lang=js&"
export * from "./question.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/apps/jenkins/workspace/新海外前端/fintopia-web-build-deploy@2/prod-mex-huawei-ssr/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5687a6c2')) {
      api.createRecord('5687a6c2', component.options)
    } else {
      api.reload('5687a6c2', component.options)
    }
    module.hot.accept("./question.vue?vue&type=template&id=5687a6c2&", function () {
      api.rerender('5687a6c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "easycash/src/app/webview/help-center/question.vue"
export default component.exports