<!-- @author xiaodongyu -->
<!-- @email xiaodongyu@yangqianguan.com -->
<!-- @date 2019-04-09 13:21:23.051 -->
<!-- @desc generated by yqg-cli@1.0.9 -->

<template>
    <ec-expand-box :text-list="questiones" />
</template>

<script type="text/babel">
import ecExpandBox from 'easycash/common/component/ec-expand-box';

export default {
    name: 'Question',

    components: {
        ecExpandBox
    },

    computed: {
        questiones() {
            const {$route: {params: {type}}} = this;

            return this.$t('webview.helpCenter.questiones')[type].map(item => ({
                title: item.q,
                content: item.a
            }));
        }
    },

    mounted() {
        const vm = this;
        vm.$watch('$route.params.type', () => {
            vm.$children[0].init();
        });
    }
};

</script>
